import { PropsWithChildren, ReactNode, useState, useEffect } from "react"
import classNames from "classnames/bind"
import { ErrorBoundary, WithFetching } from "../feedback"
import Container from "./Container"
import Card from "./Card"
import styles from "./Page.module.scss"
//import Tabs from "@mui/material/Tabs"
//import Tab from "@mui/material/Tab"
//import Box from "@mui/material/Box"
import { atom, useRecoilState } from "recoil"
import { useLocation, useNavigate } from "react-router-dom"

const swapPageState = atom({
  key: "swapPage",
  default: 0,
})

const cx = classNames.bind(styles)

interface Props extends QueryState {
  title?: string
  extra?: ReactNode
  mainClassName?: string
  small?: boolean
  sub?: boolean // used as a page in a page
}

const Page = (props: PropsWithChildren<Props>) => {
  const { title, children, small, sub, mainClassName } = props

  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const swapPageIndex = searchParams.get("type")
  const offerAsset = searchParams.get("offer")
  const askAsset = searchParams.get("ask")

  const [selectedIndex, setSelectedIndex] = useState(
    swapPageIndex ? parseInt(swapPageIndex) : 0
  )
  const [, setSwapPage] = useRecoilState(swapPageState)

  useEffect(() => {
    if (swapPageIndex) setSelectedIndex(parseInt(swapPageIndex))
  }, [swapPageIndex])

  return (
    <WithFetching {...props}>
      {(progress, wrong) => (
        <>
          {progress}

          <article className={cx(styles.page, { sub, small })}>
            <Container className={styles.grid}>
              {title && (
                <header className={styles.header}>
                  {title.split(",").map((titleItem, index) => {
                    return (
                      <h1
                        className={
                          index === selectedIndex
                            ? styles.titleSelected
                            : styles.title
                        }
                        onClick={() => {
                          setSelectedIndex(index)
                          setSwapPage(index)
                          navigate(
                            "/swap?type=" +
                              index +
                              (offerAsset ? "&offer=" + offerAsset : "") +
                              (askAsset ? "&ask=" + askAsset : "")
                          )
                        }}
                      >
                        {titleItem}
                        {index + 1 < title.split(",").length ? " | " : ""}
                      </h1>
                    )
                  })}
                </header>
              )}

              <section className={classNames(styles.main, mainClassName)}>
                {wrong ? (
                  <Card>{wrong}</Card>
                ) : (
                  <ErrorBoundary>{children}</ErrorBoundary>
                )}
              </section>
            </Container>
          </article>
        </>
      )}
    </WithFetching>
  )
}

export default Page

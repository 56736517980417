/* query */
export const LAZY_LIMIT = 999

/* connection */
export const BRIDGE = "https://bridge.walletconnect.org"

/* api */
export const ASSETS = "https://assets.hexxagon.io"
export const OBSERVER = "wss://observer.terra.dev"

/* website */
export const STATION = "https://station.terrarebels.net"
export const FINDER = "https://finder.terrarebels.net"
export const EXTENSION =
  "https://chrome.google.com/webstore/detail/akckefnapafjbpphkefbpkpcamkoaoai"
export const TUTORIAL = "https://www.terrarebels.net/guides"
export const FORUMS = "https://forums.terrarebels.net"

/* website: stake */
export const TERRA_VALIDATORS =
  "https://github.com/terra-money/validator-profiles/tree/master/validators/"

export const STAKE_ID = "https://stake.id/#/validator/"

/* ledger */
export const LEDGER_TRANSPORT_TIMEOUT = 120000

/* tx */
export const DEFAULT_GAS_ADJUSTMENT = 3
export const CLASSIC_DEFAULT_GAS_ADJUSTMENT = 5

/* swap */
export const TERRASWAP_COMMISSION_RATE = 0.003

/* placeholder */
// https://github.com/terra-money/localterra
export const SAMPLE_ADDRESS = "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v"

export const FACTORY_ADDRESS =
  "terra1xk0p3jpxmw70dt0xntyf0h73ks8ap5uravp4pruy0jherjpnnneq7z2xnx" // "terra1urr55plyyq59m0yvqdnfu4esrs3jcle7e9vpkexy2l69fp980u3qspn6lu"  //

export const DFC_INFO = {
  decimals: 6,
  icon: "https://www.dflunc.app/logo.png",
  name: "DFLunc Token",
  protocol: "dfluncswap",
  symbol: "DFC",
  token: "terra1r9laq5788d36gxmf8jkayln3g5szg4ql0nmccs",
}

export const LUCK_INFO = {
  decimals: 6,
  icon: "https://www.luckinscript.com/static/media/logo.bb96ed4728d92d4d7557.png",
  name: "LUCK Token",
  protocol: "dfluncswap",
  symbol: "LUCK",
  token: "terra13sagkcsdf039xfr4cthfv78f07ja8ey2eazktmktwqkve48ry3pqw79pzg",
}

export const CLASSIC_CHAIN_IDS = [
  "columbus-5",
  "rebel-1",
  "rebel-2",
  "rogue-1",
  "rogue-2",
  "bajor-1",
]

export const LCD_URL = "https://lcd.terra-classic.hexxagon.io/"

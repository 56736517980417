import classNames from "classnames"
import { useNetworkName } from "data/wallet"
import { Link } from "react-router-dom"
import styles from "./NetworkName.module.scss"
import { LUCK_INFO } from "config/constants"

const NetworkName = () => {
  const name = useNetworkName()

  if (name === "mainnet") return null
  return (
    <div className={classNames(styles.text, styles.warning)}>
      <Link
        style={{ marginRight: "10px" }}
        to={`/swap?type=0&offer=uluna&ask=terra1r9laq5788d36gxmf8jkayln3g5szg4ql0nmccs`}
      >
        DFC/LUNC
      </Link>

      <Link
        style={{ marginRight: "10px" }}
        to={`/swap?type=0&offer=uluna&ask=${LUCK_INFO.token}`}
      >
        LUCK/LUNC
      </Link>

      <Link
        style={{ marginRight: "10px" }}
        to={`/swap?type=0&offer=uusd&ask=${LUCK_INFO.token}`}
      >
        LUCK/USTC
      </Link>

      <Link
        style={{ marginRight: "10px" }}
        to={`/swap?type=0&offer=uusd&ask=terra1r9laq5788d36gxmf8jkayln3g5szg4ql0nmccs`}
      >
        DFC/USTC
      </Link>

      <Link to={`/swap?type=0&offer=uusd&ask=uluna`}>LUNC/USTC</Link>
    </div>
  )
}

export default NetworkName

import { useEffect, useState } from "react"
import ChartData from "./TxInfoChartData.json"
import UserDfcStat from "./UserDfcStat.json"
import { createChart, ColorType, CrosshairMode } from "lightweight-charts"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
//import Collapse from '@mui/material/Collapse';
import Paper from "@mui/material/Paper"

import moment from "moment"
import { FinderLink } from "components/general"
import Tooltip from "@mui/material/Tooltip"
//import classNames from "classnames/bind"
import styles from "./TxInfo.module.scss"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

const red = "#EF5350"
const green = "#26A69A"
const lastBlockHeight = 17504794
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
// const cx = classNames.bind(styles)

const TxInfo = () => {
  const [txData, setTxData] = useState<any>([])
  //const [dfcHoldersInfo, setDfcHoldersInfo] = useState<any>(UserDfcStat)
  const [curDate, setCurDate] = useState<string>("Now")
  const [openPrice, setOpenPrice] = useState<string>("0")
  const [highPrice, setHighPrice] = useState<string>("0")
  const [lowPrice, setLowPrice] = useState<string>("0")
  const [closePrice, setClosePrice] = useState<string>("0")
  const [volume, setVolume] = useState<string>("0")
  const [value, setValue] = useState<string>("KLINE")

  const lpContractAddr =
    "terra14p6vgwa6pt9wmxp7t54ly4ujk8cc4kehvc4dttztutpd0hmtjkns5dl0ad"
  const veContractAddr =
    "terra1txgelkw2pyldrfhkzwhsrzr6eagxecfm8klgrcf44q8a5hagefdsclmuvz"
  const burnContractAddr = "terra1eewgymwqqp0wcdllmz36xaank8lj3fcylzj3wx"

  useEffect(() => {
    if (value !== "KLINE") return
    const getSwapTxs = async (offset: number) => {
      const requestUrl = `https://fcd.terra-classic.hexxagon.io/v1/txs?offset=${offset}&limit=100&account=terra14p6vgwa6pt9wmxp7t54ly4ujk8cc4kehvc4dttztutpd0hmtjkns5dl0ad`
      const response = await fetch(requestUrl)
      const txsInfo = await response.json()
      return { txs: txsInfo.txs, nextOffset: txsInfo.next }
    }

    const processOneBatch = async (offset: number) => {
      const tokenAddr = "terra1r9laq5788d36gxmf8jkayln3g5szg4ql0nmccs"
      // const pairAddr = 'terra14p6vgwa6pt9wmxp7t54ly4ujk8cc4kehvc4dttztutpd0hmtjkns5dl0ad';
      let reachLastBlock = false
      const txsInfo = await getSwapTxs(offset)

      const processedTxs = txsInfo.txs
        .map((tx: any) => {
          if (tx.height <= lastBlockHeight) {
            reachLastBlock = true
            return {}
          }
          if (tx.tx.value.msg.length !== 1) return {}
          //console.log('chart', tx);

          const msg = tx.tx.value.msg[0]
          if (!msg.value.msg.send && !msg.value.msg.swap) return {}

          const toAddr = msg.value.contract
          const bSell = toAddr === tokenAddr
          const txHash = tx.txhash
          const timestamp = Date.parse(tx.timestamp)
          const sender = msg.value.sender

          if (bSell) {
            const sendInfo = msg.value.msg.send
            const swapMsg = JSON.parse(atob(sendInfo.msg))
            const volume = sendInfo.amount
            const price = 1 / swapMsg.swap.belief_price
            return { txHash, timestamp, sender, price, volume, bSell }
          } else {
            const swapInfo = msg.value.msg.swap
            if (swapInfo === null || swapInfo === undefined) {
              console.log("Warning:", msg.value.msg)
              return {}
            }
            const volume = swapInfo.offer_asset.amount
            const price = swapInfo.belief_price
            return { txHash, timestamp, sender, price, volume, bSell }
          }
        })
        .filter((tx: any) => tx.txHash != null)

      return { processedTxs, nextOffset: txsInfo.nextOffset, reachLastBlock }
    }

    const processBatchTxs = (batchTxs: Array<any>, timestamp: number) => {
      let open
      let close
      let high = 0
      let low = 10000000
      let sellVolume = 0
      let buyVolume = 0
      let timestamps = []
      batchTxs.forEach((tx: any, index: number) => {
        const price = parseFloat(tx.price)
        if (index === 0) {
          open = price
        }
        if (index === batchTxs.length - 1) {
          close = price
        }
        if (price > high) {
          high = price
        }
        if (price < low) {
          low = price
        }
        if (tx.bSell) {
          sellVolume += parseInt(tx.volume)
        } else {
          buyVolume += parseInt(tx.volume)
        }
        timestamps.push(tx.timestamp)
      })
      sellVolume = parseInt(sellVolume / 1000000 + "")
      buyVolume = parseInt(buyVolume / 1000000 + "")
      let time = moment(new Date(timestamp)).format("YYYY-MM-DD")
      return {
        open,
        close,
        high,
        low,
        sellVolume,
        buyVolume,
        time,
        value: close,
      }
    }
    const syncLastestTxs = async () => {
      let nextOffset = 0
      let txsProcessed = []
      while (nextOffset != null) {
        const result = await processOneBatch(nextOffset)

        //console.log('chart', result.processedTxs)
        txsProcessed.push(...result.processedTxs)

        if (result.reachLastBlock) break

        nextOffset = result.nextOffset
        sleep(500)
      }
      return txsProcessed
    }

    const generateChartData = (txsProcessed: Array<any>, period: number) => {
      if (txsProcessed.length === 0) return []

      const lastestTx = txsProcessed[0]
      let lastestTime =
        (lastestTx.timestamp - (lastestTx.timestamp % 1000)) / 1000
      let lastTime = lastestTime - (lastestTime % period)

      let chartData = []
      let curBatchTxs = []
      for (let i = 0; i < txsProcessed.length; i++) {
        const tx = txsProcessed[i]
        if (tx.timestamp / 1000 >= lastTime) {
          curBatchTxs.push(tx)
        } else {
          if (curBatchTxs.length > 0) {
            const oneBatch = processBatchTxs(
              curBatchTxs.reverse(),
              lastTime * 1000
            )
            chartData.push(oneBatch)
            curBatchTxs = []
          }
          i--
          lastTime = lastTime - period
        }
      }
      if (curBatchTxs.length > 0) {
        const oneBatch = processBatchTxs(curBatchTxs.reverse(), lastTime * 1000)
        chartData.push(oneBatch)
        curBatchTxs = []
      }
      chartData.reverse()
      return chartData
    }

    syncLastestTxs().then((latestTxs: Array<any>) => {
      const latestChartData = generateChartData(latestTxs, 24 * 3600)
      setTxData([...ChartData, ...latestChartData])
    })
  }, [value])

  useEffect(() => {
    if (txData.length === 0) return

    const parentElement = document.getElementById("TxChart") as HTMLElement
    if (parentElement == null) return

    const chartWidth = parentElement.clientWidth
    const chartHeight = parentElement.clientHeight

    const chart = createChart(parentElement, {
      width: chartWidth,
      height: chartHeight,
      layout: {
        background: { type: ColorType.Solid, color: "rgba(0, 0, 0, 0)" },
        textColor: "#d1d4dc",
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.2,
          bottom: 0.25,
        },
        borderVisible: false,
      },
      grid: {
        vertLines: {
          color: "rgba(42, 46, 57, 0)",
        },
        horzLines: {
          color: "rgba(42, 46, 57, 0.6)",
        },
      },
      watermark: {
        text: "dfswap.io",
        color: "rgba(32, 67, 182, 0.2)",
        visible: true,
      },
    })

    const candlestickSeries = chart.addCandlestickSeries({
      upColor: green,
      downColor: red,
      borderVisible: false,
      wickUpColor: green,
      wickDownColor: red,
    })

    candlestickSeries.setData(txData)

    var volumeSeries = chart.addHistogramSeries({
      color: red,
      priceFormat: {
        type: "volume",
      },
      priceScaleId: "volume",
    })

    chart.priceScale("volume").applyOptions({
      scaleMargins: {
        top: 0.85,
        bottom: 0,
      },
    })

    volumeSeries.setData(
      txData.map((tx: any) => {
        const time = tx.time
        const value = tx.sellVolume + tx.buyVolume
        const color = tx.open > tx.close ? red : green
        return { time, value, color }
      })
    )

    chart.subscribeCrosshairMove((param) => {
      if (param.time) {
        const priceInfo = param.seriesData.get(candlestickSeries) as any
        const volumeInfo = param.seriesData.get(volumeSeries) as any
        setCurDate(priceInfo?.time)
        setOpenPrice(priceInfo?.open.toFixed(0) + "")
        setHighPrice(priceInfo?.high.toFixed(0) + "")
        setLowPrice(priceInfo?.low.toFixed(0) + "")
        setClosePrice(priceInfo?.close.toFixed(0) + "")
        setVolume(volumeInfo?.value.toFixed(0) + "")
        // setCurColor(volumeInfo?.color);
      }
    })
  }, [txData])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="">
            <Tab label="K-Line" value="KLINE" className={styles.text} />
            <Tab label="Holders" value="HOLDERS" className={styles.text} />
          </TabList>
        </Box>
        <TabPanel value="KLINE">
          <Stack>
            <Box style={{ fontWeight: "bold" }}>DFC/LUNC</Box>
            <Stack direction="row" spacing={1}>
              <Box>{curDate}</Box>
              <Box>Open:{openPrice}</Box>
              <Box>High:{highPrice}</Box>
              <Box>Low:{lowPrice}</Box>
              <Box>Close:{closePrice}</Box>
              <Box>Volume:{volume} LUNC</Box>
            </Stack>
            <Box id="TxChart" style={{ width: "570px", height: "570px" }} />
          </Stack>
        </TabPanel>
        <TabPanel value="HOLDERS">
          <Stack>
            <Stack direction="row" spacing={1}>
              <Box style={{ fontWeight: "bold" }}>DFC Holders</Box>
              <Tooltip title="Non-real-time data, will be updated regularly.">
                <HelpOutlineIcon style={{ fontSize: "15px" }}></HelpOutlineIcon>
              </Tooltip>
            </Stack>

            <TableContainer
              component={Paper}
              style={{ width: 550, background: "transparent" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "gray", fontWeight: "bold" }}>
                      Address
                    </TableCell>
                    <TableCell style={{ color: "gray", fontWeight: "bold" }}>
                      Total DFC
                    </TableCell>
                    <TableCell style={{ color: "gray", fontWeight: "bold" }}>
                      In Wallet
                    </TableCell>
                    <TableCell style={{ color: "gray", fontWeight: "bold" }}>
                      In LP
                    </TableCell>
                    <TableCell style={{ color: "gray", fontWeight: "bold" }}>
                      Staked In{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dflunc.app/burnLunc"
                      >
                        P1
                      </a>
                    </TableCell>
                    <TableCell style={{ color: "gray", fontWeight: "bold" }}>
                      Unclaimed In{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dflunc.app/burnLunc"
                      >
                        P1
                      </a>
                    </TableCell>
                    <TableCell style={{ color: "gray", fontWeight: "bold" }}>
                      Staked In{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dflunc.app/stakeLP"
                      >
                        P2
                      </a>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {UserDfcStat.filter(
                    (v: any) =>
                      v.address !== lpContractAddr &&
                      v.address !== veContractAddr &&
                      v.address !== burnContractAddr
                  ).map((holderInfo: any) => (
                    <TableRow
                      key={holderInfo.address}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ color: "gray" }}>
                        <FinderLink className={styles.link} short>
                          {holderInfo.address}
                        </FinderLink>
                      </TableCell>
                      <TableCell style={{ color: "gray" }}>
                        {holderInfo.totalAmount}
                      </TableCell>
                      <TableCell style={{ color: "gray" }}>
                        {holderInfo.walletAmount}
                      </TableCell>
                      <TableCell style={{ color: "gray" }}>
                        {holderInfo.lpAmount}
                      </TableCell>
                      <TableCell style={{ color: "gray" }}>
                        {holderInfo.stakedAmount}
                      </TableCell>
                      <TableCell style={{ color: "gray" }}>
                        {holderInfo.unclaimedAmount}
                      </TableCell>
                      <TableCell style={{ color: "gray" }}>
                        <Tooltip title={`Unlocked Time: ${holderInfo.endTime}`}>
                          {holderInfo.amountInStakedLp}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

/*
{
        "address": "terra16hkscjqls9kqtsse43kytqzqs80ktucf6t4esj",
        "totalAmount": 1230,
        "walletAmount": 1230,
        "lpAmount": 0,
        "stakedAmount": 0,
        "unclaimedAmount": 0,
        "amountInStakedLp": 0,
        "endTime": ""
    },
*/

export default TxInfo

import { useQuery } from "react-query"
import axios, { AxiosError } from "axios"
import { fromPairs, toPairs } from "ramda"
import { flatten, groupBy, map, mergeAll, values } from "ramda"
import { AccAddress } from "@terra-rebels/terra.js"
import { ASSETS, FACTORY_ADDRESS, DFC_INFO, LUCK_INFO } from "config/constants"
import shuffle from "utils/shuffle"
import { queryKey, RefetchOptions } from "../query"
import { useNetworkName } from "../wallet"
import { useLCDClient } from "../queries/lcdClient"
//import pairsInfo from "config/pairs.info.json"
import cw20WhiteList from "config/cw20whitelist.json"

const config = { baseURL: ASSETS }

export const useTerraAssets = <T>(path: string, callback?: (data: T) => T) => {
  return useQuery<T, AxiosError>(
    [queryKey.TerraAssets, path],
    async () => {
      const { data } = await axios.get<T>(path, config)
      return callback?.(data) ?? data
    },
    { ...RefetchOptions.INFINITY }
  )
}

const toToken = (info: AssetInfo) => {
  return "native_token" in info
    ? info.native_token.denom
    : info.token.contract_addr
}

export const useTerraAssetsByNetwork = <T>(
  path: string,
  disabled = false,
  callback?: (data: T) => T
) => {
  const networkName = useNetworkName()
  const lcd = useLCDClient()

  return useQuery<T | undefined, AxiosError>(
    [queryKey.TerraAssets, path, networkName],
    async () => {
      let retData: any = {}
      if (path === "cw20/pairs.dex.json") {
        const { pairs } = await lcd.wasm.contractQuery<{
          pairs: PairInfo[]
        }>(FACTORY_ADDRESS, { pairs: {} })

        const newPairsInfo = {
          classic: {
            [DFC_INFO.token]: {
              dex: "default",
              type: "xyk",
              assets: ["uluna", DFC_INFO.token],
              detail: {},
            },
            [LUCK_INFO.token]: {
              dex: "default",
              type: "xyk",
              assets: ["uluna", LUCK_INFO.token],
              detail: {},
            },
          },
        }
        pairs.forEach((pair) => {
          const asset0Name = toToken(pair.asset_infos[0]) as string
          const asset1Name = toToken(pair.asset_infos[1]) as string
          const contractAddr = pair.contract_addr as string
          ;(newPairsInfo as any)["classic"][contractAddr] = {
            dex: "dfluncswap",
            type: "xyk",
            assets: [asset0Name, asset1Name],
            detail: pair,
          }
        })

        console.log("allPairs", newPairsInfo)
        retData = newPairsInfo //newPairsInfo
      } else if (path === "cw20/tokens.json") {
        retData = cw20WhiteList
      } else {
        const { data } = await axios.get<Record<NetworkName, T>>(path, config)
        retData = data
      }
      if (!retData[networkName]) return {} as T
      return callback?.(retData[networkName]) ?? retData[networkName]
    },
    { ...RefetchOptions.INFINITY, enabled: !disabled }
  )
}

export const useIBCWhitelist = () => {
  return useTerraAssetsByNetwork<IBCWhitelist>("ibc/tokens.json")
}

export const useCW20Contracts = () => {
  return useTerraAssetsByNetwork<CW20Contracts>("cw20/contracts.json")
}

export const useCW20Whitelist = (disabled = false) => {
  return useTerraAssetsByNetwork<CW20Whitelist>(
    "cw20/tokens.json",
    disabled,
    (data) => sortWhitelistCW20(shuffleByProtocol(data))
  )
}

export const useCW20Pairs = () => {
  return useTerraAssetsByNetwork<CW20Pairs>("cw20/pairs.dex.json")
}

export type ContractNames =
  | "assertLimitOrder"
  | "routeswap"
  | "tnsRegistry"
  | "tnsReverseRecord"

export type TerraContracts = Record<ContractNames, AccAddress>
export const useTerraContracts = () => {
  return useTerraAssetsByNetwork<TerraContracts>("contracts.json")
}

export const useCW721Whitelist = () => {
  return useTerraAssetsByNetwork<CW721Whitelist>(
    "cw721/contracts.json",
    undefined,
    shuffleByProtocol
  )
}

interface CW721MarketplaceItem {
  name: string
  link: string
}

export const useCW721Marketplace = () => {
  return useTerraAssets<CW721MarketplaceItem[]>("cw721/marketplace.json")
}

/* helpers */
const sortWhitelistCW20 = (data: CW20Whitelist) => {
  const sorted = toPairs(data).sort(
    ([, a], [, b]) =>
      Number(b.symbol === "ANC") - Number(a.symbol === "ANC") ||
      Number(b.protocol === "Anchor") - Number(a.protocol === "Anchor") ||
      Number(b.symbol === "MIR") - Number(a.symbol === "MIR") ||
      Number(b.protocol === "Mirror") - Number(a.protocol === "Mirror")
  )

  return fromPairs(
    sorted.map(([t, { decimals, ...item }]) => {
      return [t, { ...item, decimals: decimals ?? 6 }]
    })
  )
}

export const shuffleByProtocol = <T extends CW20Whitelist | CW721Whitelist>(
  array: T
) => {
  const shuffledPair = shuffle(
    toPairs(
      groupBy(([, { protocol, name }]) => protocol ?? name, toPairs(array))
    )
  )

  return mergeAll(flatten(map(fromPairs, values(fromPairs(shuffledPair)))))
}

import { useTranslation } from "react-i18next"
import { useIsClassic } from "data/query"
import { useNetworkName } from "data/wallet"
//import { LinkButton } from "components/general"
import { Card, Page } from "components/layout"
import { Wrong } from "components/feedback"
import { useLocation } from "react-router-dom"

import TxContext from "../TxContext"
import SwapContext from "./SwapContext"
import SingleSwapContext from "./SingleSwapContext"
import ProvideContext from "./ProvideContext"
import SwapForm from "./SwapForm"
import ProvideForm from "./ProvideForm"
import WithdrawForm from "./WithdrawForm"
import TxInfo from "./TxInfo"
import TFMSwapContext from "./TFMSwapContext"
import TFMSwapForm from "./TFMSwapForm"
import TFMPoweredBy from "./TFMPoweredBy"

import { atom, useRecoilState } from "recoil"

const swapPageState = atom({
  key: "swapPage",
  default: 0,
})

// The sequence below is required before rendering the Swap form:
// 1. `TxContext` - Fetch gas prices through, like other forms.
// 2. `SwapContext` - Complete the network request related to swap.
// 3. `SwapSingleContext` - Complete the network request not related to multiple swap

const SwapTx = () => {
  const { t } = useTranslation()
  const networkName = useNetworkName()
  const isClassic = useIsClassic()
  const location = useLocation()
  const [swapPage, setSwapPage] = useRecoilState(swapPageState)

  const searchParams = new URLSearchParams(location.search)
  const swapPageIndex = searchParams.get("type")
  if (swapPageIndex) setSwapPage(parseInt(swapPageIndex))

  // const extra = (
  //   <LinkButton to="/swap/multiple" size="small">
  //     {t("Swap multiple coins")}
  //   </LinkButton>
  // )

  if (networkName === "testnet") {
    return (
      <Page title={t("Swap")} small>
        <Card>
          <Wrong>{t("Not supported")}</Wrong>
        </Card>
      </Page>
    )
  }

  if (!isClassic)
    return (
      <Page title={t("Swap")} small extra={<TFMPoweredBy />}>
        <TxContext>
          <TFMSwapContext>
            <TFMSwapForm />
          </TFMSwapContext>
        </TxContext>
      </Page>
    )

  return (
    <Page title={t("Swap,Provide,Withdraw,Info")} small>
      <TxContext>
        <SwapContext>
          {swapPage === 0 ? (
            <SingleSwapContext>
              <SwapForm />
            </SingleSwapContext>
          ) : swapPage === 1 ? (
            <ProvideContext>
              <ProvideForm />
            </ProvideContext>
          ) : swapPage === 2 ? (
            <ProvideContext>
              <WithdrawForm />
            </ProvideContext>
          ) : (
            <ProvideContext>
              <TxInfo />
            </ProvideContext>
          )}
        </SwapContext>
      </TxContext>
    </Page>
  )
}

export default SwapTx

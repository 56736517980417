import { useCallback } from "react"
import BigNumber from "bignumber.js"
import { fromPairs, zipObj } from "ramda"
import {
  Msg,
  //Coin,
  Coins,
  MsgExecuteContract,
  //MsgSwap,
} from "@terra-rebels/terra.js"
import { isDenom, isDenomLuna } from "@terra-rebels/kitchen-utils"
//import { isDenomTerra } from "@terra-rebels/kitchen-utils"
import { TERRASWAP_COMMISSION_RATE } from "config/constants"
import { has, toPrice } from "utils/num"
import { toAsset, toTokenItem } from "utils/coin"
import { toBase64 } from "utils/data"
import { useAddress } from "data/wallet"
import { useLCDClient } from "data/queries/lcdClient"
import { useSwap } from "./SwapContext"
import { SwapSpread } from "./SingleSwapContext"

/* Call this hook after wrap component around with a SwapContext.
Then, various helper functions will be generated based on the fetched data. */

export enum SwapMode {
  ONCHAIN = "Market",
  DFLUNCSWAP = "Dfluncswap",
}

export interface SwapAssets {
  offerAsset: Token
  askAsset: Token
}

export interface SwapParams extends SwapAssets, Partial<SwapSpread> {
  amount: Amount
}

export interface ProvideParams extends SwapAssets {
  offerAmount: string
  askAmount: string
  slippageInput: number
}

/* simulated */
// rate: original ratio
// ratio: simulated ratio
// price: simulated ratio to print considering `decimals`
// belief_price: ratio to 18 decimal points
export interface SimulateResult<T = any> {
  mode: SwapMode
  query: SwapParams
  value: Amount
  ratio: Price
  rate?: Price
  payload: T
}

export type PayloadOnchain = Amount // spread
export type PayloadTerraswap = Amount // fee
export type PayloadRouteswap = string[]

const useSwapUtils = () => {
  const address = useAddress()
  const lcd = useLCDClient()
  const context = useSwap()
  const { pairs } = context

  /* helpers */
  // terraswap
  const findPair = useCallback(
    (assets: SwapAssets, dex: Dex) => {
      const { offerAsset, askAsset } = assets
      const pair = Object.entries(pairs).find(([, item]) =>
        [offerAsset, askAsset].every(
          (asset) => dex === item.dex && item.assets.includes(asset)
        )
      )

      if (!pair) return

      const [address, item] = pair
      return { address, ...item }
    },
    [pairs]
  )

  /* determine swap mode */
  // const getIsOnchainAvailable = useCallback(
  //   ({ offerAsset, askAsset }: SwapAssets) => {
  //     return [offerAsset, askAsset].every(isDenomTerraNative)
  //   },
  //   []
  // )

  const getIsTerraswapAvailable = useCallback(
    (assets: SwapAssets) => !!findPair(assets, "dfluncswap"),
    [findPair]
  )

  // const getIsRouteswapAvaialble = useCallback(
  //   (assets: SwapAssets) => {
  //     if (!contracts) return false
  //     if (getIsOnchainAvailable(assets)) return false
  //     if (getIsTerraswapAvailable(assets)) return false

  //     const r0 =
  //       getIsOnchainAvailable({ ...assets, askAsset: "uusd" }) ||
  //       getIsTerraswapAvailable({ ...assets, askAsset: "uusd" })

  //     const r1 =
  //       getIsOnchainAvailable({ ...assets, offerAsset: "uusd" }) ||
  //       getIsTerraswapAvailable({ ...assets, offerAsset: "uusd" })

  //     return r0 && r1
  //   },
  //   [contracts, getIsOnchainAvailable, getIsTerraswapAvailable]
  // )

  const getAvailableSwapModes = useCallback(
    (assets: Partial<SwapAssets>): SwapMode[] => {
      if (!validateAssets(assets)) return []

      const functions = {
        [SwapMode.ONCHAIN]: getIsTerraswapAvailable,
        [SwapMode.DFLUNCSWAP]: getIsTerraswapAvailable,
      }

      return Object.entries(functions)
        .filter(([, fn]) => fn(assets))
        .map(([key]) => key as SwapMode)
    },
    [getIsTerraswapAvailable]
  )

  const getIsSwapAvailable = (assets: Partial<SwapAssets>) =>
    !!getAvailableSwapModes(assets).length

  /* swap mode for multiple swap */
  const getSwapMode = useCallback(
    (assets: SwapAssets) => {
      const { askAsset } = assets
      if (isDenomLuna(askAsset)) {
        return getIsTerraswapAvailable(assets)
          ? SwapMode.DFLUNCSWAP
          : SwapMode.DFLUNCSWAP
      }

      return SwapMode.ONCHAIN
    },
    [getIsTerraswapAvailable]
  )

  /* simulate | execute */
  //type SimulateFn<T = any> = (params: SwapParams) => Promise<SimulateResult<T>>
  // const getOnchainParams = useCallback(
  //   ({ amount, offerAsset, askAsset, minimum_receive }: SwapParams) => {
  //     if (!address) return { msgs: [] }

  //     const getAssertMessage = () => {
  //       if (!getAssertRequired({ offerAsset, askAsset })) return
  //       if (!(contracts?.assertLimitOrder && minimum_receive)) return

  //       return new MsgExecuteContract(address, contracts.assertLimitOrder, {
  //         assert_limit_order: {
  //           offer_coin: { denom: offerAsset, amount },
  //           ask_denom: askAsset,
  //           minimum_receive,
  //         },
  //       })
  //     }

  //     const assert = getAssertMessage()
  //     const offerCoin = new Coin(offerAsset, amount)
  //     const swap = new MsgSwap(address, offerCoin, askAsset)
  //     return { msgs: assert ? [assert, swap] : [swap] }
  //   },
  //   [address, contracts]
  // )

  // const simulateOnchain = async (params: SwapParams) => {
  //   const getRate = (denom: CoinDenom) =>
  //     isDenomLuna(denom) ? "1" : getAmount(exchangeRates, denom)

  //   const { amount, offerAsset, askAsset } = params
  //   const offerCoin = new Coin(offerAsset, amount)
  //   const res = await lcd.market.swapRate(offerCoin, askAsset)
  //   const result = res.amount.toString()

  //   /* spread */
  //   const offerRate = getRate(offerAsset)
  //   const askRate = getRate(askAsset)
  //   const rate = new BigNumber(offerRate).div(askRate)
  //   const value = new BigNumber(amount).div(rate)
  //   const spread = value.minus(result).toString()

  //   if (!result) throw new Error("Simulation failed")

  //   const ratio = toPrice(new BigNumber(amount).div(result))

  //   return {
  //     mode: SwapMode.ONCHAIN,
  //     query: params,
  //     value: result,
  //     ratio,
  //     rate: toPrice(rate),
  //     payload: spread,
  //   }
  // }

  const getTerraswapParams = useCallback(
    (params: SwapParams, dex: Dex) => {
      const { amount, offerAsset, askAsset, belief_price, max_spread } = params
      const fromNative = isDenom(offerAsset)
      const pair = findPair({ offerAsset, askAsset }, dex)
      const offer_asset = toAsset(offerAsset, amount)

      if (!pair) throw new Error("Pair does not exist")
      const contract = fromNative ? pair.address : offerAsset

      /* simulate */
      const query = { simulation: { offer_asset } }

      /* execute */
      const swap =
        belief_price && max_spread ? { belief_price, max_spread } : {}
      const executeMsg = fromNative
        ? { swap: { ...swap, offer_asset } }
        : { send: { amount, contract: pair.address, msg: toBase64({ swap }) } }
      const coins = fromNative ? new Coins({ [offerAsset]: amount }) : undefined
      const msgs = address
        ? [new MsgExecuteContract(address, contract, executeMsg, coins)]
        : []

      return { pair, simulation: { contract: pair.address, query }, msgs }
    },
    [address, findPair]
  )

  const getCreatePairParams = useCallback(
    (offerAsset: string, askAsset: string, factoryAddress: string) => {
      const offerNative = isDenom(offerAsset)
      const askNative = isDenom(askAsset)
      const offerInfo = offerNative
        ? { native_token: { denom: offerAsset } }
        : { token: { contract_addr: offerAsset } }
      const askInfo = askNative
        ? { native_token: { denom: askAsset } }
        : { token: { contract_addr: askAsset } }
      const msgs: Msg[] = []

      const createPair = {
        create_pair: {
          assets: [
            {
              info: offerInfo,
              amount: "0",
            },
            {
              info: askInfo,
              amount: "0",
            },
          ],
        },
      }
      const createPairMsg = new MsgExecuteContract(
        address as string,
        factoryAddress,
        createPair
      )
      msgs.push(createPairMsg)

      return msgs
    },
    [address]
  )

  const getProvideParams = useCallback(
    (params: ProvideParams, pairAddress: string) => {
      const { offerAsset, askAsset, offerAmount, askAmount, slippageInput } =
        params
      const offerNative = isDenom(offerAsset)
      const askNative = isDenom(askAsset)
      const msgs: Msg[] = []

      const coins = new Coins()
      if (offerNative) {
        coins.set(offerAsset, offerAmount)
      } else {
        // increase allowance
        const offerIncreaseAllowance = {
          increase_allowance: {
            spender: pairAddress,
            amount: offerAmount,
          },
        }

        let offerIncreaseAllowanceContractMsg = new MsgExecuteContract(
          address as string,
          offerAsset,
          {
            ...offerIncreaseAllowance,
          }
        )
        msgs.push(offerIncreaseAllowanceContractMsg)
      }
      if (askNative) {
        coins.set(askAsset, askAmount)
      } else {
        // increase allowance
        const askIncreaseAllowance = {
          increase_allowance: {
            spender: pairAddress,
            amount: askAmount,
          },
        }

        let askIncreaseAllowanceContractMsg = new MsgExecuteContract(
          address as string,
          askAsset,
          {
            ...askIncreaseAllowance,
          }
        )
        msgs.push(askIncreaseAllowanceContractMsg)
      }

      const offerInfo = offerNative
        ? { native_token: { denom: offerAsset } }
        : { token: { contract_addr: offerAsset } }
      const askInfo = askNative
        ? { native_token: { denom: askAsset } }
        : { token: { contract_addr: askAsset } }
      const provideLiq = {
        provide_liquidity: {
          assets: [
            {
              info: offerInfo,
              amount: offerAmount,
            },
            {
              info: askInfo,
              amount: askAmount,
            },
          ],
          slippage_tolerance: "" + slippageInput / 100,
        },
      }

      let provideLiqContractMsg = new MsgExecuteContract(
        address as string,
        pairAddress,
        {
          ...provideLiq,
        },
        coins.toArray().length > 0 ? coins : undefined
      )
      msgs.push(provideLiqContractMsg)

      return msgs
    },
    [address]
  )

  const simulateTerraswap = async (
    params: SwapParams,
    dex: Dex = "dfluncswap"
  ) => {
    const mode = {
      dfluncswap: SwapMode.DFLUNCSWAP,
    }[dex]

    const query = params

    const { amount } = params
    const { pair, simulation } = getTerraswapParams(params, dex)

    if (pair.type === "stable") {
      const { return_amount: value, commission_amount } =
        await lcd.wasm.contractQuery<{
          return_amount: Amount
          commission_amount: Amount
        }>(pair.address, simulation.query)

      const payload = commission_amount
      const ratio = toPrice(new BigNumber(amount).div(value))
      return { mode, query, value, ratio, payload }
    } else {
      const { assets } = await lcd.wasm.contractQuery<{
        assets: [Asset, Asset]
      }>(simulation.contract, { pool: {} })

      const { pool, rate } = parsePool(params, assets)
      const { return_amount: value, commission_amount } = calcXyk(amount, pool)
      const payload = commission_amount
      const ratio = toPrice(new BigNumber(amount).div(value))
      return { mode, query, value, ratio, rate, payload }
    }
  }

  const getSimulateFunction = (mode: SwapMode) => {
    const simulationFunctions = {
      [SwapMode.ONCHAIN]: simulateTerraswap,
      [SwapMode.DFLUNCSWAP]: simulateTerraswap,
    }

    return simulationFunctions[mode]
  }

  const getMsgsFunction = useCallback(
    (mode: SwapMode) => {
      const getMsgs = {
        [SwapMode.ONCHAIN]: (params: SwapParams) =>
          getTerraswapParams(params, "dfluncswap").msgs,
        [SwapMode.DFLUNCSWAP]: (params: SwapParams) =>
          getTerraswapParams(params, "dfluncswap").msgs,
      }

      return getMsgs[mode]
    },
    [getTerraswapParams]
  )

  const getSimulateQuery = (params: Partial<SwapParams>) => ({
    queryKey: ["simulate.swap", params],
    queryFn: async () => {
      if (!validateParams(params)) throw new Error()
      const modes = getAvailableSwapModes(params)
      const functions = modes.map(getSimulateFunction)
      const queries = functions.map((fn) => fn(params))
      const responses = await Promise.allSettled(queries)
      const results = responses.map((result) => {
        if (result.status === "rejected") throw new Error(result.reason)
        return result.value
      })

      return {
        values: zipObj(modes, results),
        profitable: findProfitable(results),
      }
    },
    enabled: validateParams(params),
  })

  const getPoolInfo = async (offerAsset: Token, askAsset: Token) => {
    const pair = findPair({ offerAsset, askAsset }, "dfluncswap")
    if (!pair) throw new Error("Pair does not exist")

    const { assets } = await lcd.wasm.contractQuery<{
      assets: [Asset, Asset]
    }>(pair.address, { pool: {} })

    const pairsPool = fromPairs(
      assets.map(toTokenItem).map(({ amount, token }) => [token, amount])
    )

    const offerPool = pairsPool[offerAsset]
    const askPool = pairsPool[askAsset]

    return { offerPool, askPool, pair }
  }

  const getProvideSimulateQuery = async (
    offerAsset: Token,
    askAsset: Token,
    offerAmount: string,
    offerDecimals: number
  ) => {
    const { offerPool, askPool } = await getPoolInfo(offerAsset, askAsset)

    const askAmount = new BigNumber(offerAmount)
      .shiftedBy(offerDecimals)
      .div(offerPool as string)
      .multipliedBy(askPool as string)

    return askAmount
  }

  return {
    ...context,
    getIsSwapAvailable,
    getSwapMode,
    getAvailableSwapModes,
    getSimulateFunction,
    getSimulateQuery,
    getMsgsFunction,
    getPoolInfo,
    getProvideSimulateQuery,
    getTerraswapParams,
    getCreatePairParams,
    getProvideParams,
  }
}

export default useSwapUtils

/* type guard */
export const validateAssets = (
  assets: Partial<SwapAssets>
): assets is Required<SwapAssets> => {
  const { offerAsset, askAsset } = assets
  return !!offerAsset && !!askAsset && offerAsset !== askAsset
}

export const validateParams = (
  params: Partial<SwapParams>
): params is SwapParams => {
  const { amount, ...assets } = params
  return has(amount) && validateAssets(assets)
}

/* determinant */
// const getAssertRequired = ({ offerAsset, askAsset }: SwapAssets) =>
//   [offerAsset, askAsset].some(isDenomTerra) &&
//   [offerAsset, askAsset].some(isDenomLuna)

/* helpers */
const findProfitable = (results: SimulateResult[]) => {
  const index = results.reduce(
    (acc, { value }, index) =>
      new BigNumber(value).gt(results[acc].value) ? index : acc,
    0
  )

  return results[index]
}

/* calc */
const parsePool = (
  { offerAsset, askAsset }: SwapAssets,
  pairPool: [Asset, Asset]
) => {
  const pair = fromPairs(
    pairPool.map(toTokenItem).map(({ amount, token }) => [token, amount])
  )

  const offerPool = pair[offerAsset]
  const askPool = pair[askAsset]
  const pool = [offerPool, askPool] as [string, string]
  const rate = toPrice(new BigNumber(pair[offerAsset]).div(pair[askAsset]))

  return { pool, rate }
}

const calcXyk = (amount: Amount, [offerPool, askPool]: [Amount, Amount]) => {
  const returnAmount = new BigNumber(amount)
    .times(askPool)
    .div(new BigNumber(amount).plus(offerPool))
    .integerValue(BigNumber.ROUND_FLOOR)

  const spreadAmount = new BigNumber(amount)
    .times(askPool)
    .div(offerPool)
    .minus(returnAmount)
    .integerValue(BigNumber.ROUND_FLOOR)

  const commissionAmount = returnAmount
    .times(TERRASWAP_COMMISSION_RATE)
    .integerValue(BigNumber.ROUND_FLOOR)

  return {
    return_amount: returnAmount.minus(commissionAmount).toString(),
    spread_amount: spreadAmount.toString(),
    commission_amount: commissionAmount.toString(),
  }
}

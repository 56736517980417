import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useQuery } from "react-query"
import { useForm } from "react-hook-form"
import BigNumber from "bignumber.js"
import { AccAddress } from "@terra-rebels/terra.js"
//import { isDenomTerra } from "@terra-rebels/kitchen-utils"
import { toAmount } from "@terra-rebels/kitchen-utils"
import { toBase64 } from "utils/data"
import { MsgExecuteContract } from "@terra-rebels/terra.js"
import { Coin, Coins, Fee } from "@terra-rebels/terra.js"
/* helpers */
//import { has } from "utils/num"
import { getAmount, sortCoins } from "utils/coin"
import { queryKey } from "data/query"
import { useAddress } from "data/wallet"
import { useBankBalance } from "data/queries/bank"
import { useCustomTokensCW20 } from "data/settings/CustomTokens"
import { latestTxState } from "data/queries/tx"
import { useSetRecoilState } from "recoil"

/* components */
// import {
//   Form,
//   FormArrow,
//   FormAdd,
//   FormError,
//   FormWarning,
// } from "components/form"
import { Checkbox } from "components/form"
//import { Read } from "components/token"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

/* tx modules */
import { getPlaceholder, toInput, CoinInput } from "../utils"
//import validate from "../provideValidate"
import Tx, { getInitialGasDenom } from "../Tx"

/* swap modules */
import AssetFormItem from "./components/AssetFormItem"
import { AssetInput, AssetReadOnly } from "./components/AssetFormItem"
import SelectLPToken from "./components/SelectLPToken"
//import SlippageControl from "./components/SlippageControl"
//import ExpectedPrice from "./components/ExpectedPrice"
import useSwapUtils, { validateAssets } from "./useSwapUtils"
import { SwapMode } from "./useSwapUtils"
import { ProvideParams } from "./ProvideContext"
import { useSingleProvide } from "./ProvideContext"
//import styles from "./SwapForm.module.scss"
//import cw20WhiteList from "config/cw20whitelist.json"

import { FACTORY_ADDRESS, DFC_INFO, LUCK_INFO } from "config/constants"
import { useLCDClient } from "data/queries/lcdClient"
import { toTokenItem } from "utils/coin"
import { Submit } from "components/form"
import { useTx } from "../TxContext"
//import { useAuth } from "auth"
import { useConnectedWallet } from "@terra-rebels/use-wallet"

interface TxValues extends Partial<ProvideParams> {
  mode?: SwapMode
}

const WithdrawForm = () => {
  const { t } = useTranslation()
  const address = useAddress() as string
  const lcd = useLCDClient()
  const { state } = useLocation()
  const bankBalance = useBankBalance()
  const { add, list } = useCustomTokensCW20()
  //const { ...auth } = useAuth()
  const connectedWallet = useConnectedWallet()
  const setLatestTx = useSetRecoilState(latestTxState)

  const { gasPrices } = useTx()

  const tokenList = [...list, DFC_INFO, LUCK_INFO]
  const tokenInfoObj: any = {}
  tokenList.forEach(
    (tokenItem) => (tokenInfoObj[tokenItem.token as string] = tokenItem.symbol)
  )

  //const networkName = useNetworkName()
  const [lpAddr, setLpAddr] = useState("")
  const [maxAmount, setMaxAmount] = useState("0")
  const [withdrawAmount, setWithdrawAmount] = useState("0")
  const [withdrawedTokensInfo, setWithdrawedTokensInfo] = useState("")
  const [inputErr, setInputErr] = useState("")
  //const [isWithdrawing, setIsWithdrawing] = useState(false)

  /* provide context */
  const utils = useSwapUtils()
  const { getIsSwapAvailable } = utils
  const { getCreatePairParams, getProvideParams } = utils
  // const {
  //   // getSimulateQuery,
  //   // getMsgsFunction,
  //   // getSimulateFunction,
  //   // getProvideSimulateQuery,
  //   // getPoolInfo,
  // } = utils
  const { pairs, findTokenItem, findDecimals } = useSingleProvide()

  const initialOfferAsset =
    (state as Token) ??
    (getAmount(bankBalance, "uluna")
      ? "uluna"
      : sortCoins(bankBalance)[0].denom)
  const initialGasDenom = getInitialGasDenom(bankBalance)

  /* options */
  const [showAll, setShowAll] = useState(false)
  const [updateAfterTx, setUpdateAfterTx] = useState(false)

  const [poolInfo, setPoolInfo] = useState({
    tokenASymbol: "",
    tokenAAmount: "0",
    tokenBSymbol: "",
    tokenBAmount: "0",
    totalSupply: 0,
  })

  const getLPOptions = () => {
    console.log("pairs", pairs)
    const getOptionList = () =>
      Object.keys(pairs)
        .filter((item) => item !== DFC_INFO.token && item !== LUCK_INFO.token)
        .map((lpAddr) => {
          const pairInfo = pairs[lpAddr]
          let tokenA =
            pairInfo.assets[0] === "uluna"
              ? "LUNC"
              : pairInfo.assets[0] === "uusd"
              ? "USTC"
              : (pairInfo.assets[0] as string)
          let tokenB =
            pairInfo.assets[1] === "uluna"
              ? "LUNC"
              : pairInfo.assets[1] === "uusd"
              ? "USTC"
              : (pairInfo.assets[1] as string)
          //console.log('pairInfo', lpAddr, pairInfo)
          const tokenASymbol = tokenInfoObj[tokenA as string]
            ? tokenInfoObj[tokenA as string]
            : tokenA
          const tokenBSymbol = tokenInfoObj[tokenB as string]
            ? tokenInfoObj[tokenB as string]
            : tokenB
          return {
            token: lpAddr,
            decimals: 6,
            symbol: tokenASymbol + " + " + tokenBSymbol,
            value: lpAddr,
            muted: false,
            hidden: false,
            showName: false,
            tokenA,
            tokenB,
          }
        })

    return [{ title: t("LP"), children: getOptionList() }]
  }

  /* form */
  const form = useForm<TxValues>({
    mode: "onChange",
    defaultValues: { offerAsset: initialOfferAsset, slippageInput: 1 },
  })

  const { trigger, watch, setValue, formState } = form
  const { errors } = formState
  const values = watch()
  const { mode, offerAsset, askAsset, offerInput, askInput, slippageInput } =
    values

  useEffect(() => {
    // validate input on change mode
    if (mode) {
      trigger("offerInput")
      trigger("askInput")
    }
  }, [mode, trigger])

  const assets = useMemo(
    () => ({ offerAsset, askAsset }),
    [offerAsset, askAsset]
  )

  // const slippageParams = useMemo(
  //   () => ({ offerAsset, askAsset, offerInput, askInput, slippageInput }),
  //   [askAsset, offerInput, askInput, offerAsset, slippageInput]
  // )

  const offerTokenItem = offerAsset ? findTokenItem(offerAsset) : undefined
  const offerDecimals = offerAsset ? findDecimals(offerAsset) : undefined
  const askTokenItem = askAsset ? findTokenItem(askAsset) : undefined
  const askDecimals = askAsset ? findDecimals(askAsset) : undefined

  //const offerAmount = offerInput ? toAmount(offerInput, { decimals: offerDecimals }) : undefined
  //const askAmount = askInput ? toAmount(askInput, { decimals: offerDecimals }) : undefined

  /* simulate | execute */
  //const params = { offerAmount, askAmount, ...assets }
  //const availableSwapModes = getAvailableSwapModes(assets)
  const isSwapAvailable =
    getIsSwapAvailable(assets) || poolInfo?.totalSupply > 0
  //const simulateQuery = getSimulateQuery(params)

  /* simulate */
  // const { data: simulationResults, isFetching } = useQuery({
  //   ...simulateQuery,
  //   onSuccess: ({ profitable }) => setValue("mode", profitable?.mode),
  // })

  // /* Simulated value to create tx */
  // // Simulated for all possible modes
  // // Do not simulate again even if the mode changes
  // const results = simulationResults?.values
  // const result = results && mode && results[mode]
  // const simulatedValue = result?.value
  // const simulatedRatio = result?.ratio

  // useEffect(() => {
  //   // Set ratio on simulate
  //   if (simulatedRatio) setValue("ratio", simulatedRatio)
  // }, [simulatedRatio, setValue])

  /* Select asset */
  const onSelectLP = () => {
    return async (value: string) => {
      setWithdrawedTokensInfo("")
      setWithdrawAmount("0")

      setLpAddr(value)
      const pairInfo = pairs[value]
      // console.log(pairInfo)
      const { balance } = await lcd.wasm.contractQuery<{ balance: Amount }>(
        pairInfo.detail.liquidity_token,
        { balance: { address } }
      )
      setMaxAmount(new BigNumber(balance).shiftedBy(-6).toString())

      const { assets, total_share } = await lcd.wasm.contractQuery<{
        assets: [Asset, Asset]
        total_share: Amount
      }>(value, { pool: {} })
      console.log(assets, total_share)
      const tokenItemA = toTokenItem(assets[0])
      const tokenItemB = toTokenItem(assets[1])
      let tokenA =
        tokenItemA.token === "uluna"
          ? "LUNC"
          : tokenItemA.token === "uusd"
          ? "USTC"
          : tokenItemA.token
      let tokenB =
        tokenItemB.token === "uluna"
          ? "LUNC"
          : tokenItemB.token === "uusd"
          ? "USTC"
          : tokenItemB.token
      //console.log('pairInfo', lpAddr, pairInfo)
      const tokenASymbol = tokenInfoObj[tokenA as string]
        ? tokenInfoObj[tokenA as string]
        : tokenA
      const tokenBSymbol = tokenInfoObj[tokenB as string]
        ? tokenInfoObj[tokenB as string]
        : tokenB
      //const tokenAAmount = tokenA == poolInfo.assets[0].info
      setPoolInfo({
        tokenASymbol,
        tokenAAmount: tokenItemA.amount,
        tokenBSymbol,
        tokenBAmount: tokenItemB.amount,
        totalSupply: parseInt(total_share),
      })
    }
  }

  useEffect(() => {
    if (poolInfo.totalSupply > 0 && withdrawAmount && withdrawAmount !== "0") {
      if (new BigNumber(withdrawAmount).gt(new BigNumber(maxAmount))) {
        setInputErr(t("Insufficient balance in your wallet"))
      } else {
        setInputErr("")
      }
      const ratio = new BigNumber(withdrawAmount)
        .shiftedBy(6)
        .div(new BigNumber(poolInfo.totalSupply))
      const withrawableTokenA = ratio
        .multipliedBy(new BigNumber(poolInfo.tokenAAmount))
        .shiftedBy(-6)
        .toFixed(6)
        .toString()
      const withrawableTokenB = ratio
        .multipliedBy(new BigNumber(poolInfo.tokenBAmount))
        .shiftedBy(-6)
        .toFixed(6)
        .toString()
      setWithdrawedTokensInfo(
        withrawableTokenA +
          " " +
          poolInfo.tokenASymbol +
          " + " +
          withrawableTokenB +
          " " +
          poolInfo.tokenBSymbol
      )
    }
  }, [withdrawAmount, maxAmount, poolInfo, t])

  /* tx */
  const offerBalance = offerTokenItem?.balance
  const askBalance = askTokenItem?.balance
  const createTx = useCallback(
    (values: TxValues) => {
      const { offerAsset, askAsset, offerInput, askInput, slippageInput } =
        values
      if (!(offerInput && askInput && offerAsset && askAsset && slippageInput))
        return

      const offerDecimals = findDecimals(offerAsset)
      const offerAmount = toAmount(offerInput, { decimals: offerDecimals })
      const askDecimals = findDecimals(askAsset)
      const askAmount = toAmount(askInput, { decimals: askDecimals })
      if (!offerBalance || new BigNumber(offerAmount).gt(offerBalance)) return
      if (!askBalance || new BigNumber(askAmount).gt(askBalance)) return

      const params = {
        offerAmount,
        askAmount,
        offerAsset,
        askAsset,
        slippageInput,
      }
      // const { pairs } = options
      //if (!validateParams(params)) return
      let msgs
      if (isSwapAvailable) {
        let pairAddress = ""
        Object.keys(pairs).forEach((pairAddr) => {
          const assets = pairs[pairAddr].assets
          if (
            (assets[0] === offerAsset && assets[1] === askAsset) ||
            (assets[0] === askAsset && assets[1] === offerAsset)
          ) {
            pairAddress = pairAddr
          }
        })
        msgs = getProvideParams(params, pairAddress)
      } else {
        msgs = getCreatePairParams(offerAsset, askAsset, FACTORY_ADDRESS)
      }

      /* slippage */
      //const expected = calcExpected({ ...params, offerInput, askInput, slippageInput, ratio })
      return { msgs }
    },
    [
      offerBalance,
      askBalance,
      findDecimals,
      getCreatePairParams,
      getProvideParams,
      isSwapAvailable,
      pairs,
    ]
  )

  /* fee */
  const { data: estimationTxValues } = useQuery(
    ["estimationTxValues", { assets, offerInput, askInput, slippageInput }],
    async () => {
      if (!(validateAssets(assets) && offerInput && askInput && slippageInput))
        return
      const { offerAsset, askAsset } = assets
      return { offerAsset, askAsset, offerInput, askInput, slippageInput }
    }
  )

  const taxRequired = mode !== SwapMode.ONCHAIN
  let token = offerAsset
  const coins = [] as CoinInput[]
  if (offerAsset === "uluna" || offerAsset === "uusd") {
    coins.push({
      input: offerInput ? offerInput : 0,
      denom: offerAsset,
      taxRequired,
    })
  }
  if (askAsset === "uluna" || askAsset === "uusd") {
    coins.push({ input: askInput ? askInput : 0, denom: askAsset, taxRequired })
  }
  if (token !== "uluna" && token !== "uusd") {
    if (askAsset === "uluna" || askAsset === "uusd") {
      token = askAsset
    }
  }
  const decimals = offerDecimals
  const tx = {
    token,
    decimals,
    coins,
    offerBalance,
    askBalance,
    initialGasDenom,
    estimationTxValues,
    createTx,
    taxRequired,
    onPost: () => {
      setUpdateAfterTx(!updateAfterTx)
      // add custom token on ask cw20
      if (!(askAsset && AccAddress.validate(askAsset) && askTokenItem)) return
      const { balance, ...rest } = askTokenItem
      add(rest as CustomTokenCW20)
    },
    queryKeys: [offerAsset, askAsset]
      .filter((asset) => asset && AccAddress.validate(asset))
      .map((token) => [
        queryKey.wasm.contractQuery,
        token,
        { balance: address },
      ]),
  }

  const withdraw = async () => {
    const pairInfo = pairs[lpAddr]
    /* execute */
    const withdraw_liquidity = {}
    const executeMsg = {
      send: {
        amount: new BigNumber(withdrawAmount).shiftedBy(6).toString(),
        contract: lpAddr,
        msg: toBase64({ withdraw_liquidity }),
      },
    }

    const msgs = address
      ? [
          new MsgExecuteContract(
            address,
            pairInfo.detail.liquidity_token,
            executeMsg
          ),
        ]
      : []

    const gasDenom = "uusd"
    const unsignedTx = await lcd.tx.create([{ address }], {
      msgs,
      feeDenoms: [gasDenom],
    })

    const gasPrice = gasPrices[gasDenom]
    const gasAmount = new BigNumber(unsignedTx.auth_info.fee.gas_limit)
      .times(gasPrice)
      .integerValue(BigNumber.ROUND_CEIL)
      .toString()
    const gasFee = { amount: gasAmount, denom: gasDenom }
    const gasCoins = new Coins([Coin.fromData(gasFee)])
    const fee = new Fee(unsignedTx.auth_info.fee.gas_limit, gasCoins)
    // setIsWithdrawing(true)
    // console.log('withrawing start...')
    const result = await connectedWallet?.post({ msgs, fee })
    //console.log('withrawing over...', result)
    setLatestTx({ txhash: result?.result.txhash as string })

    if (!result?.success) {
      throw new Error(result?.result.raw_log)
    }
    onSelectLP()
  }

  const disabled = t("Withdraw LP")

  return (
    <Tx {...tx} disabled={disabled}>
      {({ max, fee, submit }) => (
        <div>
          <AssetFormItem
            label={t("LP")}
            extra={"Balance: " + maxAmount}
            error={inputErr}
          >
            <SelectLPToken
              value={lpAddr}
              onChange={onSelectLP()}
              options={getLPOptions()}
              checkbox={
                <Checkbox
                  checked={showAll}
                  onChange={() => setShowAll(!showAll)}
                >
                  {t("Show all")}
                </Checkbox>
              }
              addonAfter={
                <AssetInput
                  value={withdrawAmount}
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                  inputMode="decimal"
                  placeholder={getPlaceholder(offerDecimals)}
                  onFocus={max.reset}
                  autoFocus
                />
              }
            />
          </AssetFormItem>

          <ArrowDropDownIcon
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />

          <AssetFormItem
            label={t("Received")}
            extra={max.render(async (value) => {
              // Do not use automatic max here
              // Confusion arises as the amount changes and simulates again
              setValue("askInput", toInput(value, askDecimals))
              await trigger("askInput")
            })}
            error={errors.askInput?.message}
          >
            <AssetReadOnly>
              <div style={{ height: "20px" }}>{withdrawedTokensInfo}</div>
            </AssetReadOnly>
          </AssetFormItem>
          <Submit type="button" onClick={withdraw}>
            {t("Withdraw")}
          </Submit>
        </div>
      )}
    </Tx>
  )
}

export default WithdrawForm

import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { useQuery } from "react-query"
import { useForm } from "react-hook-form"
import BigNumber from "bignumber.js"
import { AccAddress } from "@terra-rebels/terra.js"
import { isDenomTerra } from "@terra-rebels/kitchen-utils"
import { toAmount } from "@terra-rebels/kitchen-utils"

/* helpers */
import { has } from "utils/num"
import { getAmount, sortCoins } from "utils/coin"
import { queryKey } from "data/query"
import { useAddress } from "data/wallet"
import { useBankBalance } from "data/queries/bank"
import { useCustomTokensCW20 } from "data/settings/CustomTokens"

/* components */
import {
  Form,
  // FormArrow,
  // FormAdd,
  // FormError,
  FormWarning,
  FormHelp,
} from "components/form"
import { Checkbox } from "components/form"
//import { Read } from "components/token"

import AddIcon from "@mui/icons-material/Add"

/* tx modules */
import { getPlaceholder, toInput, CoinInput } from "../utils"
import validate from "../provideValidate"
import Tx, { getInitialGasDenom } from "../Tx"

/* swap modules */
import AssetFormItem from "./components/AssetFormItem"
import { AssetInput } from "./components/AssetFormItem"
import SelectToken from "./components/SelectToken"
import SlippageControl from "./components/SlippageControl"
//import ExpectedPrice from "./components/ExpectedPrice"
import useSwapUtils, { validateAssets } from "./useSwapUtils"
import { SwapMode } from "./useSwapUtils"
import { ProvideParams } from "./ProvideContext"
import { useSingleProvide } from "./ProvideContext"
import styles from "./ProvideForm.module.scss"
//import cw20WhiteList from "config/cw20whitelist.json"

import { FACTORY_ADDRESS } from "config/constants"

interface TxValues extends Partial<ProvideParams> {
  mode?: SwapMode
}

const ProvideForm = () => {
  const { t } = useTranslation()
  const address = useAddress()
  const location = useLocation()
  const navigate = useNavigate()
  const bankBalance = useBankBalance()
  const { add } = useCustomTokensCW20()
  //const networkName = useNetworkName()

  /* provide context */
  const utils = useSwapUtils()
  const { getIsSwapAvailable, getAvailableSwapModes } = utils
  const { getCreatePairParams, getProvideParams } = utils
  const {
    //getMsgsFunction,
    //getSimulateFunction,
    getSimulateQuery,
    //getProvideSimulateQuery,
    getPoolInfo,
  } = utils
  const { options, pairs, findTokenItem, findDecimals } = useSingleProvide()

  const searchParams = new URLSearchParams(location.search)

  const offerState = searchParams.get("offer")
  const initialOfferAsset =
    (offerState as Token) ??
    (getAmount(bankBalance, "uluna")
      ? "uluna"
      : sortCoins(bankBalance)[0].denom)

  const initialAskAsset = (searchParams.get("ask") as Token) ?? ""

  const initialGasDenom = getInitialGasDenom(bankBalance)

  /* options */
  const [showAll, setShowAll] = useState(false)
  const [updateAfterTx, setUpdateAfterTx] = useState(false)
  const [tokenSymbolMap, setTokenSymbolMap] = useState<Record<string, string>>(
    {}
  )

  const [poolInfo, setPoolInfo] = useState({
    offerPool: "0",
    askPool: "0",
    pair: { address: "" },
  })

  const getOptions = (key: "offerAsset" | "askAsset") => {
    const { coins, tokens } = options
    //console.log("options", options)

    const getOptionList = (list: TokenItemWithBalance[]) =>
      list
        .filter(
          (item) =>
            item.token === "uluna" ||
            item.token === "uusd" ||
            !item.token.startsWith("u")
        )
        .map((item) => {
          const { token: value, balance, symbol } = item
          tokenSymbolMap[value] = symbol
          setTokenSymbolMap(tokenSymbolMap)
          const muted = {
            offerAsset:
              !!askAsset &&
              !getIsSwapAvailable({ offerAsset: value, askAsset }),
            askAsset:
              !!offerAsset &&
              !getIsSwapAvailable({ offerAsset, askAsset: value }),
          }[key]

          const hidden = key === "offerAsset" && !showAll && !has(balance)
          return { ...item, value, muted, hidden }
        })

    return [
      { title: t("Coins"), children: getOptionList(coins) },
      { title: t("Tokens"), children: getOptionList(tokens) },
    ]
  }

  /* form */
  const form = useForm<TxValues>({
    mode: "onChange",
    defaultValues: {
      offerAsset: initialOfferAsset,
      askAsset: initialAskAsset,
      slippageInput: 1,
    },
  })

  const { register, trigger, watch, setValue, handleSubmit, formState } = form
  const { errors } = formState
  const values = watch()
  const { mode, offerAsset, askAsset, offerInput, askInput, slippageInput } =
    values

  useEffect(() => {
    // validate input on change mode
    if (mode) {
      trigger("offerInput")
      trigger("askInput")
    }
  }, [mode, trigger])

  const assets = useMemo(
    () => ({ offerAsset, askAsset }),
    [offerAsset, askAsset]
  )

  // const slippageParams = useMemo(
  //   () => ({ offerAsset, askAsset, offerInput, askInput, slippageInput }),
  //   [askAsset, offerInput, askInput, offerAsset, slippageInput]
  // )

  // eslint-disable-next-line
  useMemo(async () => {
    try {
      const poolInfoData = await getPoolInfo(
        offerAsset as Token,
        askAsset as Token
      )
      console.log(updateAfterTx, poolInfoData)
      setPoolInfo(poolInfoData)
      return poolInfoData
    } catch (error) {
      return { offerPool: "0", askPool: "0" }
    }
  }, [offerAsset, askAsset, updateAfterTx]) // eslint-disable-line

  const offerTokenItem = offerAsset ? findTokenItem(offerAsset) : undefined
  const offerDecimals = offerAsset ? findDecimals(offerAsset) : undefined
  const askTokenItem = askAsset ? findTokenItem(askAsset) : undefined
  const askDecimals = askAsset ? findDecimals(askAsset) : undefined

  const offerAmount = offerInput
    ? toAmount(offerInput, { decimals: offerDecimals })
    : undefined
  const askAmount = askInput
    ? toAmount(askInput, { decimals: offerDecimals })
    : undefined

  // const swapAssets = () => {
  //   setValue("offerAsset", askAsset)
  //   setValue("askAsset", offerAsset)
  //   setValue("offerInput", undefined)
  //   setValue("askInput", undefined)
  //   trigger("offerInput")
  //   trigger("askInput")
  // }

  // useEffect(() => {
  //   console.log("ask -> offer", askInput, offerAsset, poolInfo)
  //   if (
  //     askInput &&
  //     offerAsset &&
  //     askAsset &&
  //     poolInfo.offerPool !== "0" &&
  //     poolInfo.askPool !== "0"
  //   ) {
  //     const offerAmount = new BigNumber(askInput)
  //       .shiftedBy(askDecimals as number)
  //       .div(poolInfo.askPool)
  //       .multipliedBy(poolInfo.offerPool)
  //       .integerValue()
  //     setValue(
  //       "offerInput",
  //       offerAmount.shiftedBy((offerDecimals as number) * -1).toNumber()
  //     )
  //     trigger("offerInput")
  //   }
  // }, [offerAsset, askAsset, askInput]) // , poolInfo, askDecimals, offerDecimals, setValue, trigger

  // eslint-disable-next-line no-use-before-define
  useEffect(() => {
    console.log("offer -> ask", offerInput, askAsset, poolInfo)
    if (
      offerInput &&
      offerAsset &&
      askAsset &&
      poolInfo.offerPool !== "0" &&
      poolInfo.askPool !== "0"
    ) {
      const askAmount = new BigNumber(offerInput)
        .shiftedBy(offerDecimals as number)
        .div(poolInfo.offerPool)
        .multipliedBy(poolInfo.askPool)
        .integerValue()
      setValue(
        "askInput",
        askAmount.shiftedBy((askDecimals as number) * -1).toNumber()
      )
      trigger("askInput")
    }
  }, [offerAsset, askAsset, offerInput]) // eslint-disable-line

  /* simulate | execute */
  const params = { offerAmount, askAmount, ...assets }
  //const availableSwapModes = getAvailableSwapModes(assets)
  const isSwapAvailable =
    getIsSwapAvailable(assets) || poolInfo?.pair?.address !== ""
  const simulateQuery = getSimulateQuery(params)

  /* simulate */
  const { isFetching } = useQuery({
    ...simulateQuery,
    onSuccess: ({ profitable }) => setValue("mode", profitable?.mode),
  })

  /* Simulated value to create tx */
  // Simulated for all possible modes
  // Do not simulate again even if the mode changes
  // const results = simulationResults?.values
  // const result = results && mode && results[mode]
  // const simulatedValue = result?.value
  // const simulatedRatio = result?.ratio

  // useEffect(() => {
  //   // Set ratio on simulate
  //   if (simulatedRatio) setValue("ratio", simulatedRatio)
  // }, [simulatedRatio, setValue])

  /* Select asset */
  const onSelectAsset = (key: "offerAsset" | "askAsset") => {
    return async (value: Token) => {
      const assets = {
        offerAsset: { offerAsset: value, askAsset },
        askAsset: { offerAsset, askAsset: value },
      }[key]

      // set mode if only one available
      const availableSwapModes = getAvailableSwapModes(assets)
      const availableSwapMode =
        availableSwapModes?.length === 1 ? availableSwapModes[0] : undefined
      setValue("mode", availableSwapMode)

      let offerValueInUrl = assets.offerAsset ?? ""
      let askValueInUrl = assets.askAsset ?? ""
      // empty opposite asset if select the same asset
      if (assets.offerAsset === assets.askAsset) {
        setValue(key === "offerAsset" ? "askAsset" : "offerAsset", undefined)
        key === "offerAsset" ? (askValueInUrl = "") : (offerValueInUrl = "")
      }
      let newUrl = "/swap"
      if (offerValueInUrl !== "" || askValueInUrl !== "") {
        newUrl += "?"
        if (offerValueInUrl !== "") {
          newUrl += "offer=" + offerValueInUrl
        }
        if (askValueInUrl !== "") {
          newUrl += "&ask=" + askValueInUrl
        }
      }
      navigate(newUrl)

      // focus on input if select offer asset
      if (key === "offerAsset") {
        form.resetField("offerInput")
        form.setFocus("offerInput")
      }

      if (key === "askAsset") {
        form.resetField("askInput")
        form.setFocus("askInput")
      }

      setValue(key, value)
    }
  }

  /* tx */
  const offerBalance = offerTokenItem?.balance
  const askBalance = askTokenItem?.balance
  const createTx = useCallback(
    (values: TxValues) => {
      const { offerAsset, askAsset, offerInput, askInput, slippageInput } =
        values
      if (!(offerInput && askInput && offerAsset && askAsset && slippageInput))
        return

      const offerDecimals = findDecimals(offerAsset)
      const offerAmount = toAmount(offerInput, { decimals: offerDecimals })
      const askDecimals = findDecimals(askAsset)
      const askAmount = toAmount(askInput, { decimals: askDecimals })
      if (!offerBalance || new BigNumber(offerAmount).gt(offerBalance)) return
      if (!askBalance || new BigNumber(askAmount).gt(askBalance)) return

      const params = {
        offerAmount,
        askAmount,
        offerAsset,
        askAsset,
        slippageInput,
      }
      // const { pairs } = options
      //if (!validateParams(params)) return
      let msgs
      if (isSwapAvailable) {
        let pairAddress = ""
        Object.keys(pairs).forEach((pairAddr) => {
          const assets = pairs[pairAddr].assets
          if (
            (assets[0] === offerAsset && assets[1] === askAsset) ||
            (assets[0] === askAsset && assets[1] === offerAsset)
          ) {
            pairAddress = pairAddr
          }
        })
        msgs = getProvideParams(params, pairAddress)
      } else {
        msgs = getCreatePairParams(offerAsset, askAsset, FACTORY_ADDRESS)
      }

      /* slippage */
      //const expected = calcExpected({ ...params, offerInput, askInput, slippageInput, ratio })
      return { msgs }
    },
    [
      offerBalance,
      askBalance,
      findDecimals,
      getCreatePairParams,
      getProvideParams,
      isSwapAvailable,
      pairs,
    ]
  )

  /* fee */
  const { data: estimationTxValues } = useQuery(
    ["estimationTxValues", { assets, offerInput, askInput, slippageInput }],
    async () => {
      if (!(validateAssets(assets) && offerInput && askInput && slippageInput))
        return
      const { offerAsset, askAsset } = assets
      return { offerAsset, askAsset, offerInput, askInput, slippageInput }
    }
  )

  const taxRequired = mode !== SwapMode.ONCHAIN
  let token = offerAsset
  const coins = [] as CoinInput[]
  if (offerAsset === "uluna" || offerAsset === "uusd") {
    coins.push({
      input: offerInput ? offerInput : 0,
      denom: offerAsset,
      taxRequired,
    })
  }
  if (askAsset === "uluna" || askAsset === "uusd") {
    coins.push({ input: askInput ? askInput : 0, denom: askAsset, taxRequired })
  }
  if (token !== "uluna" && token !== "uusd") {
    if (askAsset === "uluna" || askAsset === "uusd") {
      token = askAsset
    }
  }
  const decimals = offerDecimals
  const tx = {
    token,
    decimals,
    coins,
    offerBalance,
    askBalance,
    initialGasDenom,
    estimationTxValues,
    createTx,
    taxRequired,
    onPost: () => {
      setUpdateAfterTx(!updateAfterTx)
      // add custom token on ask cw20
      if (!(askAsset && AccAddress.validate(askAsset) && askTokenItem)) return
      const { balance, ...rest } = askTokenItem
      add(rest as CustomTokenCW20)
    },
    queryKeys: [offerAsset, askAsset]
      .filter((asset) => asset && AccAddress.validate(asset))
      .map((token) => [
        queryKey.wasm.contractQuery,
        token,
        { balance: address },
      ]),
  }

  const disabled = isFetching ? t("Simulating...") : false // !isSwapAvailable ? t("Create LP") : false

  const slippageDisabled = [offerAsset, askAsset].every(isDenomTerra)

  return (
    <Tx {...tx} disabled={disabled}>
      {({ max, fee, submit }) => (
        <Form onSubmit={handleSubmit(submit.fn)}>
          {/* {renderRadioGroup()} */}
          <Tx {...tx} balance={offerBalance} disabled={disabled}>
            {({ max, fee, submit }) => (
              <AssetFormItem
                label={t("Asset")}
                extra={max.render(async (value) => {
                  // Do not use automatic max here
                  // Confusion arises as the amount changes and simulates again
                  setValue("offerInput", toInput(value, offerDecimals))
                  await trigger("offerInput")
                })}
                error={errors.offerInput?.message}
              >
                <SelectToken
                  value={offerAsset}
                  onChange={onSelectAsset("offerAsset")}
                  options={getOptions("offerAsset")}
                  checkbox={
                    <Checkbox
                      checked={showAll}
                      onChange={() => setShowAll(!showAll)}
                    >
                      {t("Show all")}
                    </Checkbox>
                  }
                  addonAfter={
                    <AssetInput
                      {...register("offerInput", {
                        valueAsNumber: true,
                        validate: validate.offerInput(
                          toInput(max.amount, offerDecimals),
                          offerDecimals
                        ),
                      })}
                      inputMode="decimal"
                      placeholder={getPlaceholder(offerDecimals)}
                      onFocus={max.reset}
                      autoFocus
                    />
                  }
                />
              </AssetFormItem>
            )}
          </Tx>

          <AddIcon />

          <Tx {...tx} balance={askBalance} token={askAsset} disabled={disabled}>
            {({ max, fee, submit }) => (
              <AssetFormItem
                label={t("Asset")}
                extra={max.render(async (value) => {
                  // Do not use automatic max here
                  // Confusion arises as the amount changes and simulates again
                  setValue("askInput", toInput(value, askDecimals))
                  await trigger("askInput")
                })}
                error={errors.askInput?.message}
              >
                <SelectToken
                  value={askAsset}
                  onChange={onSelectAsset("askAsset")}
                  options={getOptions("askAsset")}
                  addonAfter={
                    <AssetInput
                      {...register("askInput", {
                        valueAsNumber: true,
                        validate: validate.askInput(
                          toInput(max.amount, askDecimals),
                          askDecimals
                        ),
                      })}
                      inputMode="decimal"
                      placeholder={getPlaceholder(askDecimals)}
                    />
                  }
                />
              </AssetFormItem>
            )}
          </Tx>
          {!slippageDisabled && (
            <SlippageControl
              {...register("slippageInput", {
                valueAsNumber: true,
              })}
              input={slippageInput} // to warn
              inputMode="decimal"
              placeholder={getPlaceholder(2)}
              error={errors.slippageInput?.message}
            />
          )}
          {validateAssets(assets) && !isSwapAvailable ? (
            <FormWarning>
              {t(
                "Pair does not exist! Before you provide liquidity, the pair will be created firstly."
              )}
            </FormWarning>
          ) : poolInfo.askPool === "0" && offerAsset && askAsset ? (
            <FormHelp>
              {t("Pair has been created, but there is no liquidity.")}
            </FormHelp>
          ) : poolInfo.askPool !== "0" ? (
            <div className={styles.header}>
              <div>Total Liquidity:</div>
              <div>
                {new BigNumber(poolInfo.offerPool).shiftedBy(-6).toString() +
                  " " +
                  tokenSymbolMap[offerAsset as string] +
                  " + " +
                  new BigNumber(poolInfo.askPool).shiftedBy(-6).toString() +
                  " " +
                  tokenSymbolMap[askAsset as string]}
              </div>
            </div>
          ) : (
            ""
          )}
          <Tx {...tx} disabled={disabled}>
            {({ max, fee, submit }) => {
              return submit.button
            }}
          </Tx>
        </Form>
      )}
    </Tx>
  )
}

export default ProvideForm
